<template>
  <b-list-group class="promos-list">
    <b-list-group-item
      v-for="promo in promoShown"
      :key="promo.id"
      class="d-flex align-items-center"
      @click="handlePromoModal(promo)"
    >
      <b-media vertical-align="center">
        <template #aside>
          <b-avatar
            v-if="promo.store_products[0]"
            size="32"
            :src="promo.store_products[0].product_attributes.logo"
          />
        </template>
        <div class="media">
          <div>
            <p class="text-break bold" v-if="promo.store_products[0]">
              {{ promo.store_products[0].product_attributes.name }}
            </p>
            <p class="text-break bold" v-else>
              {{ "Todos los productos" }}
            </p>

            <p
              v-if="promo.store_products[0]"
              class="font-weight-bold d-block text-break"
            >
              {{ promo.store_products[0].product_attributes.name }}
            </p>
            <p
              v-if="!promo.store_products[0]"
              class="font-weight-bold d-block text-break"
            >
              {{ promo.product_name }}
            </p>
            <small class="text-muted">
              {{ promo.begins_at | fullDate }} -
            </small>
            <small class="text-muted">
              {{ promo.end_date | fullDate }}
            </small>
          </div>
          <div class="ml-auto">
            <b-button
              v-if="promo.is_published === false && promo.is_canceled === false"
              :variant="'success'"
              size="sm"
              @click.stop="publishPromo(promo.id)"
            >
              Activar
            </b-button>
            <b-button
              v-else-if="
                promo.is_published === true && promo.is_canceled === false
              "
              :variant="'danger'"
              size="sm"
              @click.stop="cancelPromo(promo.id)"
            >
              Cancelar
            </b-button>
          </div>
        </div>
      </b-media>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    promoShown: {
      type: Array,
      default: () => [],
    },
    cancelPromo: {
      type: Function,
      default: () => {},
    },
    publishPromo: {
      type: Function,
      default: () => {},
    },
    handlePromoModal: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
