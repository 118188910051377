<template>
  <b-badge
    class="ml-1 whitespace-normal"
    pill
    :variant="
      selectedPromo.promo_type === 'free_product'
        ? 'light-success'
        : selectedPromo.promo_type === 'price_discount'
        ? 'light-warning'
        : 'primary'
    "
  >
    <slot />
  </b-badge>
</template>

<script>
export default {
  props: {
    selectedPromo: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
