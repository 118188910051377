<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      layout="horizontal"
      finish-button-text="Confirmar"
      next-button-text="Siguiente"
      back-button-text="Regresar"
      class="wizard-vertical mb-3"
      shape="square"
      step-size="sm"
      @on-complete="formSubmitted"
    >
      <!-- promo type -->
      <tab-content title="Tipo de promoción" :before-change="validationForm">
        <validation-observer ref="accountRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Tipo de promoción</h5>
              <small class="text-muted">
                Selecciona la promoción deseada
              </small>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card
                class="custom-card cursor-pointer mx-auto"
                border-variant="primary"
                :bg-variant="
                  promo.promo_type === 'price_discount' ? 'primary' : ''
                "
                @click="promo.promo_type = 'price_discount'"
              >
                <b-card-body
                  class="
                    promo-card
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                >
                  Código promocional
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- logic -->
      <tab-content title="Configuración" :before-change="validationFormInfo">
        <validation-observer ref="infoRules" tag="form">
          <b-row v-if="promo.promo_type === 'price_discount'">
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Configuración General</h5>
              <small class="text-muted"
                >Ingresa la cantidad mínima para que se aplique la promoción y
                el porcentage de descuento</small
              >
            </b-col>
            <b-col md="6">
              <b-form-group label="Código promocional" label-for="promo_code">
                <validation-provider
                  #default="{ errors }"
                  name="Código promocional"
                  rules="required"
                >
                  <b-form-input
                    id="promo_code"
                    v-model="promo.promo_code"
                    placeholder="HSKJAL"
                    :state="errors.length > 0 ? false : null"
                    size="lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Descuento %" label-for="price_discount">
                <validation-provider
                  #default="{ errors }"
                  name="Descuento"
                  rules="required"
                >
                  <b-form-input
                    id="price_discount"
                    v-model="promo.discount"
                    placeholder="10%"
                    :state="errors.length > 0 ? false : null"
                    size="lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- From-to date promotion -->
      <tab-content title="Validez" :before-change="validationFormDates">
        <validation-observer ref="datesValidity" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Fecha valida de promoción</h5>
              <small class="text-muted"
                >Ingresa las fechas para las cuales tu promoción estará
                vigente</small
              >
            </b-col>
            <b-col md="6">
              <b-form-group label="Fecha inicial" label-for="begins_at">
                <validation-provider
                  #default="{ errors }"
                  name="Fecha inicial"
                  rules="required"
                >
                  <b-form-input
                    type="date"
                    id="begins_at"
                    v-model="promo.begins_at"
                    placeholder="1"
                    :state="errors.length > 0 ? false : null"
                    size="lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Fecha final" label-for="ends_at">
                <validation-provider
                  #default="{ errors }"
                  name="Fecha final"
                  rules="required"
                >
                  <b-form-input
                    type="date"
                    id="ends_at"
                    v-model="promo.ends_at"
                    placeholder="10%"
                    :state="errors.length > 0 ? false : null"
                    size="lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import "@/@core/scss/vue/libs/vue-wizard.scss";
import BaseCropper from "@/@core/components/BaseCropper.vue";
// import { StreamBarcodeReader } from 'vue-barcode-reader'
import { QrcodeStream } from "vue-qrcode-reader";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BCardBody,
} from "bootstrap-vue";
import { required, email } from "@validations";
import Cleave from "vue-cleave-component";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BCardBody,
    vSelect,
    Cleave,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BaseCropper,
    // StreamBarcodeReader,
    QrcodeStream,
  },
  data() {
    return {
      promo: {
        begins_at: "",
        ends_at: "",
        promo_type: "", // 'reward_points' and 'price_discount'
        needs_to_buy: 1,
        gets_you: 1,
        discount: 0,
        reward_points: 0,
        promo_code: "",
        store_id: this.$route.params.id,
        store_product_ids: [],
      },
      required,
    };
  },
  methods: {
    ...mapActions("promotions", ["createPromotion"]),
    formSubmitted() {
      // Create Promotion
      const userData = JSON.parse(localStorage.getItem("userData"));
      this.promo.needs_to_buy = 1;
      this.promo.gets_you = 1;

      this.createPromotion(this.promo)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Form Submitted",
              icon: "CoffeeIcon",
              variant: "success",
              text: "Promoción creada",
            },
          });
          this.$emit("promoCodeCreated");
        })
        .catch((error) => {
          const baseMsg = error.response.data.messages?.base ?? null;
          const freeProductMsg =
            error.response.data.messages?.free_product ?? null;
          const rewardPointsMsg =
            error.response.data.messages?.reward_points ?? null;
          const datesMsg = error.response.data.messages?.dates ?? null;
          const discountMsg =
            error.response.data.messages?.price_discount ?? null;
          const invalidUpdateMsg =
            error.response.data.messages?.invalid_update ?? null;
          const invalidPromotionPublishingMsg =
            error.response.data.messages?.invalid_promotion_publishing ?? null;
          if (baseMsg != null) {
            this.$swal({
              title: "ERROR!",
              text: error.response.data.messages.base[0],
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
          if (freeProductMsg != null) {
            this.$swal({
              title: "ERROR!",
              text: error.response.data.messages.free_product[0],
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
          if (datesMsg != null) {
            this.$swal({
              title: "ERROR!",
              text: error.response.data.messages.dates[0],
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
          if (invalidPromotionPublishingMsg != null) {
            this.$swal({
              title: "ERROR!",
              text: error.response.data.messages
                .invalid_promotion_publishing[0],
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
          if (rewardPointsMsg != null) {
            this.$swal({
              title: "ERROR!",
              text: error.response.data.messages.reward_points[0],
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
          if (discountMsg != null) {
            this.$swal({
              title: "ERROR!",
              text: error.response.data.messages.price_discount[0],
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
          if (invalidUpdateMsg != null) {
            this.$swal({
              title: "ERROR!",
              text: error.response.data.messages.invalid_update[0],
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
          baseMsg = null;
          freeProductMsg = null;
          rewardPointsMsg = null;
          datesMsg = null;
          discountMsg = null;
          invalidUpdateMsg = null;
          invalidPromotionPublishingMsg = null;
        });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormDates() {
      return new Promise((resolve, reject) => {
        this.$refs.datesValidity.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    onDecode(result) {
      this.qr_scan_result = result.split("/");
      this.walleat.bracelet_number =
        this.qr_scan_result[this.qr_scan_result.length - 1].toString();
      this.$bvModal.hide("scanner");
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";

.promo-card {
  height: 120px;
  width: 190px;
}

.custom-card {
  /* Adjust the width as per your needs */
  width: 230px; /* Example width */
}

.vue-form-wizard {
  .wizard-header {
    display: none;
  }
  ul.wizard-nav-pills,
  .wizard-progress-with-circle {
    display: none !important;
  }
}
</style>
