<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row class="d-flex align-items-center justify-content-between">
        <!-- Per Page -->
        <b-col
          cols="12"
          sm="auto"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <h3>Promos</h3>
        </b-col>
        <b-button
          v-if="$route.params.store_product_id != null"
          variant="primary"
          v-b-modal="`add-promo`"
        >
          <feather-icon icon="PlusIcon" />
          Agregar promoción
        </b-button>
        <b-button
          v-if="$route.params.store_product_id == null"
          variant="primary"
          v-b-modal="`add-promo-code`"
        >
          <feather-icon icon="PlusIcon" />
          Agregar código promocional
        </b-button>
        <!-- Add new promo-->
        <b-modal ref="addPromoModal" id="add-promo" hide-footer size="lg">
          <template v-slot:modal-title>
            <div>Agregar promoción</div>
          </template>
          <promos-new-wizard @promotionCreated="closeModal" />
        </b-modal>
        <b-modal ref="addPromoModal" id="add-promo-code" hide-footer size="lg">
          <template v-slot:modal-title>
            <div>Agregar promoción</div>
          </template>
          <promos-new-wizard-for-promotion-code
            @promoCodeCreated="closeModalPromoCode"
          />
        </b-modal>
      </b-row>
    </div>

    <promos-table
      :promoShown="promoShown"
      :cancelPromo="cancelPromo"
      :publishPromo="publishPromo"
    />

    <promos-list
      :promoShown="promoShown"
      :cancelPromo="cancelPromo"
      :publishPromo="publishPromo"
      :handlePromoModal="handlePromoModal"
    />

    <promos-modal :selectedPromo="selectedPromo" />

    <!-- Footer: Pagination -->
    <div class="mx-2 mb-2 mt-1">
      <pagination
        :loading="loading"
        :handlePagination="handlePagination"
        :pagination="pagination"
        :entriesPerPage.sync="entriesPerPage"
      />
    </div>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { ValidationObserver } from "vee-validate";
import { mapActions, mapGetters } from "vuex";

import PromosNewWizard from "@/@core/components/promos/PromosNewWizard.vue";
import PromosNewWizardForPromotionCode from "@/@core/components/promos/PromosNewWizardForPromotionCode.vue";
import Pagination from "@/@core/components/Pagination.vue";
import PromosTable from "@/@core/components/promos/PromosTable.vue";
import PromosList from "@/@core/components/promos/PromosList.vue";
import PromosModal from "@/@core/components/promos/PromosModal.vue";

import router from "@/router";
import { required } from "@validations";

export default {
  components: {
    vSelect,
    ValidationObserver,

    PromosNewWizard,
    PromosNewWizardForPromotionCode,
    Pagination,
    PromosTable,
    PromosList,
    PromosModal,
  },
  directives: {
    Ripple,
  },
  props: {
    dates: {
      type: [Array, String],
      default: () => null,
    },
  },
  data() {
    return {
      required,
      promoShown: null,
      loading: false,
      selectedPromo: null,
      entriesPerPage: "10",
      promotion: {
        id: null,
      },
    };
  },
  computed: {
    ...mapGetters("promotions", ["pagination", "promotions"]),
  },
  watch: {
    entriesPerPage() {
      this.loading = true;
      if (router.currentRoute.params.store_product_id != null) {
        this.fetchPromotions({
          by_product: router.currentRoute.params.store_product_id,
          by_is_canceled: false,
          by_is_applicable: "today",
          meta: {
            pagination: {
              page: this.pagination.page,
              per_page: this.entriesPerPage,
            },
          },
        }).then(() => {
          this.loading = false;
          this.promoShown = this.promotions;
        });
      }
      if (router.currentRoute.params.store_product_id == null) {
        this.fetchPromotions({
          by_store: router.currentRoute.params.store_id,
          by_is_canceled: false,
          by_is_applicable: "today",
          meta: {
            pagination: {
              page: this.pagination.page,
              per_page: this.entriesPerPage,
            },
          },
        }).then(() => {
          this.loading = false;
          this.promoShown = this.promotions;
        });
      }
    },
  },

  beforeMount() {
    if (router.currentRoute.params.store_product_id != null) {
      this.fetchPromotions({
        by_product: router.currentRoute.params.store_product_id,
        by_is_canceled: false,
        by_is_applicable: "today",
      })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.promoShown = this.promotions;
        });
    }
    if (router.currentRoute.params.store_product_id == null) {
      this.fetchPromotions({
        by_store: router.currentRoute.params.store_id,
        by_is_canceled: false,
        by_is_applicable: "today",
      })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.promoShown = this.promotions;
        });
    }
  },

  mounted() {
    this.promoShown = this.promotions;
  },
  methods: {
    ...mapActions("promotions", [
      "fetchPromotions",
      "publishPromotion",
      "cancelPromotion",
    ]),

    handlePromoModal(promo) {
      this.selectedPromo = promo;
      this.$bvModal.show("promos-modal");
    },

    closeModal() {
      this.$bvModal.hide("add-promo");
      this.fetchPromotions({
        by_product: router.currentRoute.params.store_product_id,
        by_is_canceled: false,
        by_is_applicable: "today",
      })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.promoShown = this.promotions;
        });
    },

    closeModalPromoCode() {
      this.$bvModal.hide("add-promo-code");
      this.fetchPromotions({
        by_product: router.currentRoute.params.store_product_id,
        by_is_canceled: false,
        by_is_applicable: "today",
      })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.promoShown = this.promotions;
        });
    },

    handlePagination({ page, per_page }) {
      this.fetchPromotions({
        by_product: router.currentRoute.params.store_product_id,
        by_is_canceled: false,
        by_is_applicable: "today",
        meta: {
          pagination: {
            page,
            per_page,
          },
        },
      }).then(() => {
        this.promoShown = this.promotions;
      });
    },

    publishPromo(promotionId) {
      this.promotion.id = promotionId;
      this.publishPromotion(this.promotion).then((response) => {
        this.fetchPromotions({
          by_product: router.currentRoute.params.store_product_id,
          by_is_canceled: false,
          by_is_applicable: "today",
        })
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
            this.promoShown = this.promotions;
          });
      });
    },

    cancelPromo(promotionId) {
      this.promotion.id = promotionId;
      this.cancelPromotion(this.promotion).then((response) => {
        this.fetchPromotions({
          by_product: router.currentRoute.params.store_product_id,
          by_is_canceled: false,
          by_is_applicable: "today",
        })
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
            this.promoShown = this.promotions;
          });
      });
    },
  },
};
</script>

<style lang="scss">
.media {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
}
</style>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.search-input {
  max-width: 200px;
}

.promos-table {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}

.promos-list {
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
}
</style>
