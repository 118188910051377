<template>
  <b-table
    ref="refInvoiceListTable"
    :items="promoShown"
    responsive
    :fields="tableColumns"
    primary-key="id"
    show-empty
    empty-text="No hay promociones"
    class="text-center promos-table"
  >
    <!-- Column: Promotion type -->
    <template #cell(promo_type)="data">
      <div>
        <b-badge
          pill
          :variant="
            data.value === 'free_product'
              ? 'light-success'
              : data.value === 'price_discount'
              ? 'light-warning'
              : 'primary'
          "
        >
          {{ data.item.promo_type | promoType }} -
          <span v-if="data.item.promo_type === 'free_product'">
            {{ data.item.gets_you }} {{ "unidad gratis" }}
          </span>
          <!-- badge for price discount promotion-->
          <span v-else-if="data.item.promo_type === 'price_discount'">
            {{ data.item.discount }} {{ "% de descuento" }}
          </span>
          <!-- badge for reward points promotion-->
          <span v-else-if="data.item.promo_type === 'reward_points'">
            {{ data.item.reward_points }} {{ "% en puntos" }}
          </span>
          <span v-else-if="data.item.promo_type === 'promo_code'">
            {{ data.item.discount }} {{ "% de descuento" }}
          </span>
        </b-badge>
      </div>
    </template>

    <template #cell(product_name)="data">
      <b-media :id="data.item.product_name" vertical-align="center">
        <template #aside>
          <b-avatar
            v-if="data.item.store_products[0]"
            size="32"
            :src="data.item.store_products[0].product_attributes.logo"
          />
        </template>
        <span
          v-if="data.item.store_products[0]"
          class="font-weight-bold d-block text-nowrap"
        >
          {{ data.item.store_products[0].product_attributes.name }}
        </span>
        <span
          v-if="!data.item.store_products[0]"
          class="font-weight-bold d-block text-nowrap"
        >
          {{ "Todos los productos" }}
        </span>
        <small v-if="data.item.store_products[0]" class="text-muted">{{
          data.item.store_products[0].product_attributes.variant
        }}</small>
      </b-media>
    </template>

    <!-- Column: Begins Date -->
    <template #cell(valid_at)="data">
      <p class="text-nowrap mr-2">
        Desde: {{ data.item.begins_at | fullDate }}
      </p>
      <p class="text-nowrap mr-2">Hasta: {{ data.item.ends_at | fullDate }}</p>
    </template>

    <!-- Column: Ends Date -->
    <template #cell(ends_at)="data">
      <span class="text-nowrap mr-2">
        {{ data.value | fullDate }}
      </span>
    </template>

    <!-- Column: Needs to buy-->
    <template #cell(needs_to_buy)="data">
      <template>
        <b-badge
          pill
          :variant="
            data.item.promo_type === 'free_product'
              ? 'light-success'
              : data.item.promo_type === 'price_discount'
              ? 'light-warning'
              : 'primary'
          "
        >
          {{ data.item.needs_to_buy }} {{ "unidades" }}
        </b-badge>
      </template>
    </template>

    <!-- Column: Promo Code-->
    <template #cell(promo_code)="data">
      <template>
        <b-badge
          pill
          :variant="
            data.item.promo_type === 'free_product'
              ? 'light-success'
              : data.item.promo_type === 'price_discount'
              ? 'light-warning'
              : 'primary'
          "
        >
          {{ data.item.promo_code }}
        </b-badge>
      </template>
    </template>

    <!-- Column: Publish or cancel promotion-->
    <template #cell(status)="data">
      <template>
        <b-button
          v-if="
            data.item.is_published === false && data.item.is_canceled === false
          "
          :variant="'success'"
          @click="publishPromo(data.item.id)"
        >
          Activar
        </b-button>
        <b-button
          v-else-if="
            data.item.is_published === true && data.item.is_canceled === false
          "
          :variant="'danger'"
          @click="cancelPromo(data.item.id)"
        >
          Cancelar
        </b-button>
      </template>
    </template>
  </b-table>
</template>

<script>
export default {
  props: {
    promoShown: {
      type: Array,
      default: () => [],
    },
    cancelPromo: {
      type: Function,
      default: () => {},
    },
    publishPromo: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      tableColumns: [
        {
          key: "status",
          label: "Status",
        },
        {
          key: "product_name",
          label: "Producto",
        },
        {
          key: "promo_type",
          label: "Tipo de Promoción",
        },
        {
          key: "needs_to_buy",
          label: "Compra mínima",
        },
        {
          key: "promo_code",
          label: "Código promocional",
        },
        {
          key: "valid_at",
          label: "Valido",
        },
      ],
    };
  },
};
</script>
